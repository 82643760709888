import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import Content from "../components/404-content"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window
  return (
    <>
      {browser && (
        <Layout type={1}>
          <Menu type={2} btype={3} />
          <Content />
          <Footer type={1} />
        </Layout>
      )}
    </>
  )
}

export default NotFoundPage
