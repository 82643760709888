import React from "react"
import styles from "./404-content.module.css"

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loginCard}>
        <div className={styles.login}>
          <h1>This page does not exist</h1>
        </div>
      </div>
    </div>
  )
}

export default Content
